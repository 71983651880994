import {Fragment, useEffect, useRef, useState} from 'react';

import {Popover, Transition} from '@headlessui/react';
import {Icon} from '@iconify/react';
import {
  Project,
  Organization,
} from '@polarsignals/client-grpc-web/polarsignals/project/v1alpha1/project';
import {View} from '@polarsignals/client-grpc-web/polarsignals/view/v1alpha1/view';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {usePopper} from 'react-popper';
import {Tooltip} from 'react-tooltip';
import {useColorMode} from 'theme-ui';

import useActiveProject from 'hooks/data/useActiveProject';
import useViews from 'hooks/data/useViews';

import AddOrganizationModal from '../NavbarActions/Modals/AddOrganizationModal';
import AddProjectModal from '../NavbarActions/Modals/AddProjectModal';

import OrganizationSelector from './OrganizationSelector';
import ProjectSelector from './ProjectSelector';
import ViewSelector from './ViewSelector';
import {findOrganizationWithOrganizationId} from '..';
import {ProjectAccessControl} from 'components/AccessControl/ProjectAccessControl';

interface Props {
  currentOrg: Organization | undefined;
  organizations: Organization[] | undefined;
  activeProject: Project | null;
  views: View[] | undefined;
  activeView: View | undefined;
  selectProject: (
    project: Project,
    organization: Organization,
    initiatedByEffect?: boolean
  ) => void;
}

const Selector = ({
  organizations,
  currentOrg,
  activeProject,
  selectProject,
  views,
  activeView,
}: Props) => {
  const [hoveredOrganizations, setHoveredOrganizations] = useState<Organization[] | undefined>(
    undefined
  );
  const [projects, setProjects] = useState<Organization | undefined>(undefined);
  const [projectViews, setProjectViews] = useState<View[] | undefined>(undefined);
  const [hoveredProject, setHoveredProject] = useState<Project | undefined>(undefined);

  const [isOrganizationSelectorClicked, setIsOrganizationSelectorClicked] =
    useState<boolean>(false);
  const [isProjectSelectorClicked, setIsProjectSelectorClicked] = useState<boolean>(false);
  const [isViewSelectorClicked, setIsViewSelectorClicked] = useState<boolean>(false);

  const [isAddProjectModalOpen, setIsAddProjectModalOpen] = useState<boolean>(false);
  const [isAddOrganizationModalOpen, setIsAddOrganizationModalOpen] = useState<boolean>(false);

  const referenceElementRef = useRef<HTMLDivElement>(null);
  const popperElementRef = useRef<HTMLDivElement>(null);
  const {styles, attributes} = usePopper(referenceElementRef.current, popperElementRef.current);

  const [colorMode] = useColorMode();
  const router = useRouter();
  const {mutations} = useActiveProject();
  const {mutations: viewMutations} = useViews();

  const isViewPage = router.pathname === '/projects/[project-id]/views/[view-slug]';
  const userIsOnOrgPage =
    router.pathname === '/organizations/[organizationId]' ||
    router.pathname === '/organizations/[organizationId]/settings';

  useEffect(() => {
    setProjects(projectsForCurrentOrg);
  }, [organizations, currentOrg]);

  useEffect(() => {
    setProjectViews(views);
  }, [views, activeProject]);

  useEffect(() => {
    setHoveredOrganizations(organizations);
  }, [organizations]);

  const projectsForCurrentOrg = organizations?.find(org => org.id === currentOrg?.id);

  const switchProjectsShown = (organization: Organization | undefined) => {
    setProjects(organization);
  };

  const switchViewsShown = async (project: Project | undefined) => {
    if (project === undefined) {
      setProjectViews(undefined);
    }
  };

  const navigateToOrgPage = (organization: Organization) => {
    viewMutations.setActiveView(undefined);
    mutations.setActiveProject(organization.id, '');
    router.push(`/organizations/${organization.id}`);
  };

  const navigateToProjectPage = () => {
    viewMutations.setActiveView(undefined);
    mutations.setActiveProject(projects?.id, activeProject?.id);
    router.push(`/projects/${activeProject?.id}`);
  };

  const orgToShow = userIsOnOrgPage
    ? findOrganizationWithOrganizationId(organizations, router.query['organizationId'] as string)
    : currentOrg;

  return (
    <>
      <Popover className="relative">
        {({open}) => (
          <div className="relative" ref={referenceElementRef}>
            <div className="flex w-full items-center gap-2">
              <div>
                <Icon icon="iconoir:slash" className="h-6 w-6 text-gray-300" />
              </div>

              <div>
                <div
                  className="cursor-pointer text-sm font-medium text-gray-700 dark:text-white"
                  onClick={() => navigateToOrgPage(orgToShow as Organization)}
                >
                  <span>{orgToShow?.name}</span>
                </div>
              </div>

              <Popover.Button
                onClick={() => {
                  setIsOrganizationSelectorClicked(true);
                  setIsProjectSelectorClicked(false);
                  setIsViewSelectorClicked(false);
                }}
                className={`${
                  isOrganizationSelectorClicked && open ? 'bg-gray-200 dark:bg-gray-800' : ''
                } group inline-flex items-center rounded-sm px-1 py-2 text-base font-medium text-white hover:text-opacity-100 focus:outline-none`}
              >
                <Icon icon="ph:caret-up-down-bold" className="h-4 w-auto text-gray-400" />
              </Popover.Button>

              {!userIsOnOrgPage && activeProject?.id !== undefined && (
                <>
                  <div>
                    <Icon icon="iconoir:slash" className="h-6 w-6 text-gray-300" />
                  </div>

                  <div
                    className="cursor-pointer text-sm font-medium text-gray-700 dark:text-white"
                    onClick={() => navigateToProjectPage()}
                  >
                    <span>{activeProject?.name}</span>
                  </div>

                  <Popover.Button
                    onClick={() => {
                      setIsProjectSelectorClicked(true);
                      setIsOrganizationSelectorClicked(false);
                      setIsViewSelectorClicked(false);
                      switchProjectsShown(projectsForCurrentOrg);
                    }}
                    className={`${
                      isProjectSelectorClicked && open ? 'bg-gray-200 dark:bg-gray-800' : ''
                    } group inline-flex items-center rounded-sm px-1 py-2 text-base font-medium text-white hover:text-opacity-100 focus:outline-none`}
                  >
                    <Icon icon="ph:caret-up-down-bold" className="h-4 w-auto text-gray-400" />
                  </Popover.Button>
                </>
              )}

              {activeView !== undefined && isViewPage && (
                <>
                  <div>
                    <Icon icon="iconoir:slash" className="h-6 w-6 text-gray-300" />
                  </div>

                  <div className="flex items-center gap-2">
                    <Link
                      href={`/projects/${activeProject?.id}/views/${activeView?.slug}`}
                      className="flex items-center text-sm font-medium text-gray-700 dark:text-white"
                    >
                      <span>{activeView?.name}</span>
                    </Link>

                    <>
                      <ProjectAccessControl
                        projectId={activeProject?.id as string}
                        accessLevel="write"
                      >
                        <Icon
                          icon="material-symbols-light:settings-b-roll-outline"
                          width={20}
                          height={20}
                          className="ml-1 cursor-pointer text-gray-800 dark:text-white"
                          data-tooltip-id="activeViewExplanationTooltip"
                          data-tooltip-html={activeView?.description}
                          onClick={() => {
                            router.push({
                              query: {
                                ...router.query,
                                show_view_drawer: true,
                              },
                            });
                          }}
                        />
                      </ProjectAccessControl>
                      {activeView?.description !== undefined &&
                        activeView.description.length > 0 && (
                          <Tooltip
                            id="activeViewExplanationTooltip"
                            style={{
                              backgroundColor: colorMode === 'dark' ? '#374151' : '#F9FAFB',
                              color: colorMode === 'dark' ? '#fff' : '#4B5563',
                              borderRadius: '8px',
                              padding: '12px',
                              textAlign: 'left',
                              zIndex: 99,
                              width: '304px',
                            }}
                            place="right"
                          />
                        )}
                    </>
                  </div>

                  <Popover.Button
                    onClick={() => {
                      setIsViewSelectorClicked(true);
                      setIsOrganizationSelectorClicked(false);
                      setIsProjectSelectorClicked(false);
                    }}
                    className={`${
                      isViewSelectorClicked && open ? 'bg-gray-200 dark:bg-gray-800' : ''
                    } group inline-flex items-center rounded-sm px-1 py-2 text-base font-medium text-white hover:text-opacity-100 focus:outline-none`}
                  >
                    <Icon icon="ph:caret-up-down-bold" className="h-4 w-auto text-gray-400" />
                  </Popover.Button>
                </>
              )}
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                ref={popperElementRef}
                style={{
                  ...styles.popper,
                  zIndex: 99,
                  top: '40px',
                  transform: 'translate3d(0px, 8px, 0px)',
                }}
                {...attributes.popper}
              >
                {({close}) => (
                  <div className="flex overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                    <OrganizationSelector
                      organizations={hoveredOrganizations as Organization[]}
                      isOrganizationSelectorClicked={isOrganizationSelectorClicked}
                      currentOrg={currentOrg}
                      switchProjectsShown={switchProjectsShown}
                      switchViewsShown={switchViewsShown}
                      navigateToOrgPage={navigateToOrgPage}
                      setIsAddOrganizationModalOpen={setIsAddOrganizationModalOpen}
                      close={close}
                    />

                    {projects && (
                      <ProjectSelector
                        organization={projects}
                        isProjectSelectorClicked={isProjectSelectorClicked}
                        selectProject={selectProject}
                        setProjectViews={setProjectViews}
                        activeProject={activeProject}
                        setIsAddProjectModalOpen={setIsAddProjectModalOpen}
                        close={close}
                        setHoveredProject={setHoveredProject}
                      />
                    )}

                    {projectViews && (
                      <ViewSelector
                        views={projectViews}
                        isViewSelectorClicked={isViewSelectorClicked}
                        activeView={activeView}
                        selectedView={activeView as View}
                        close={close}
                        activeProject={activeProject as Project}
                        hoveredProject={hoveredProject}
                      />
                    )}
                  </div>
                )}
              </Popover.Panel>
            </Transition>
          </div>
        )}
      </Popover>
      <AddOrganizationModal
        isModalOpen={isAddOrganizationModalOpen}
        closeModal={() => {
          setIsAddOrganizationModalOpen(false);
        }}
      />

      <AddProjectModal
        isModalOpen={isAddProjectModalOpen}
        closeModal={() => {
          setIsAddProjectModalOpen(false);
        }}
      />
    </>
  );
};

export default Selector;
