// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies
// @generated from protobuf file "polarsignals/admin/v1alpha1/admin.proto" (package "polarsignals.admin.v1alpha1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Discount } from "../../billing/v1alpha1/billing";
import { Timestamp } from "../../../google/protobuf/timestamp";
/**
 * UpdateOrganizationPriceIDRequest is the request object for UpdateOrganizationPriceID.
 *
 * @generated from protobuf message polarsignals.admin.v1alpha1.UpdateOrganizationPriceIDRequest
 */
export interface UpdateOrganizationPriceIDRequest {
    /**
     * ID is the id of the organization to update.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * PriceID is the new price id for the organization.
     *
     * @generated from protobuf field: string price_id = 2;
     */
    priceId: string;
}
/**
 * UpdateOrganizationPriceIDResponse is the response object for UpdateOrganizationPriceID.
 *
 * @generated from protobuf message polarsignals.admin.v1alpha1.UpdateOrganizationPriceIDResponse
 */
export interface UpdateOrganizationPriceIDResponse {
}
/**
 * UpdateOrganizationTrialEndRequest is the request object for UpdateOrganizationTrialEnd.
 *
 * @generated from protobuf message polarsignals.admin.v1alpha1.UpdateOrganizationTrialEndRequest
 */
export interface UpdateOrganizationTrialEndRequest {
    /**
     * ID is the id of the organization to update.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * TrialEnd is the new trial end for the organization.
     *
     * @generated from protobuf field: google.protobuf.Timestamp trial_end = 2;
     */
    trialEnd?: Timestamp;
}
/**
 * UpdateOrganizationTrialEndResponse is the response object for UpdateOrganizationTrialEnd.
 *
 * @generated from protobuf message polarsignals.admin.v1alpha1.UpdateOrganizationTrialEndResponse
 */
export interface UpdateOrganizationTrialEndResponse {
}
/**
 * GetAllOrganizationUsageDataRequest is the request object for GetAllOrganizationUsageData.
 *
 * @generated from protobuf message polarsignals.admin.v1alpha1.GetAllOrganizationUsageDataRequest
 */
export interface GetAllOrganizationUsageDataRequest {
}
/**
 * OrganizationUsageData is the usage data for an organization.
 *
 * @generated from protobuf message polarsignals.admin.v1alpha1.OrganizationUsageData
 */
export interface OrganizationUsageData {
    /**
     * ID is the id of the organization.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * Name is the name of the organization.
     *
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * Projects is the projects in the organization.
     *
     * @generated from protobuf field: repeated polarsignals.admin.v1alpha1.ProjectMeta projects = 3;
     */
    projects: ProjectMeta[];
    /**
     * Last 4 weeks of usage.
     *
     * @generated from protobuf field: int64 bytes_4w = 4 [json_name = "bytes4w"];
     */
    bytes4W: bigint;
    /**
     * Last 7 days of usage.
     *
     * @generated from protobuf field: int64 bytes_7d = 5 [json_name = "bytes7d"];
     */
    bytes7D: bigint;
    /**
     * Last 24 hours of usage.
     *
     * @generated from protobuf field: int64 bytes_24h = 6 [json_name = "bytes24h"];
     */
    bytes24H: bigint;
    /**
     * Last 24 hours rate.
     *
     * @generated from protobuf field: int64 bytes_24h_rate = 7 [json_name = "bytes24hRate"];
     */
    bytes24HRate: bigint;
    /**
     * Last 4 weeks of queries.
     *
     * @generated from protobuf field: int64 queries_4w = 8 [json_name = "queries4w"];
     */
    queries4W: bigint;
    /**
     * Last 1d of queries.
     *
     * @generated from protobuf field: int64 queries_24h = 9 [json_name = "queries24h"];
     */
    queries24H: bigint;
    /**
     * Billing Estimate.
     *
     * @generated from protobuf field: polarsignals.admin.v1alpha1.BillingEstimate billing_estimate = 10;
     */
    billingEstimate?: BillingEstimate;
    /**
     * Last Calendar Month usage.
     *
     * @generated from protobuf field: int64 bytes_last_calendar_month = 11;
     */
    bytesLastCalendarMonth: bigint;
}
/**
 * BillingEstimate is the billing estimate for an organization.
 *
 * @generated from protobuf message polarsignals.admin.v1alpha1.BillingEstimate
 */
export interface BillingEstimate {
    /**
     * Billing period start.
     *
     * @generated from protobuf field: int32 period_start = 1;
     */
    periodStart: number;
    /**
     * Billing period end.
     *
     * @generated from protobuf field: int32 period_end = 2;
     */
    periodEnd: number;
    /**
     * Cost per unit.
     *
     * @generated from protobuf field: int32 unit_amount = 3;
     */
    unitAmount: number;
    /**
     * Units used.
     *
     * @generated from protobuf field: int32 units = 4;
     */
    units: number;
    /**
     * Current amount due.
     *
     * @generated from protobuf field: int32 current_amount_due = 5;
     */
    currentAmountDue: number;
    /**
     * Is Subscription Active.
     *
     * @generated from protobuf field: bool is_subscription_active = 6;
     */
    isSubscriptionActive: boolean;
    /**
     * PriceTiers.
     *
     * @generated from protobuf field: repeated polarsignals.admin.v1alpha1.PriceTier price_tiers = 7;
     */
    priceTiers: PriceTier[];
    /**
     * Discount.
     *
     * @generated from protobuf field: polarsignals.billing.v1alpha1.Discount discount = 8;
     */
    discount?: Discount;
}
/**
 * PriceTier is the price tier for an organization.
 *
 * @generated from protobuf message polarsignals.admin.v1alpha1.PriceTier
 */
export interface PriceTier {
    /**
     * Unit Amount.
     *
     * @generated from protobuf field: int32 unit_amount = 1;
     */
    unitAmount: number;
    /**
     * Up To.
     *
     * @generated from protobuf field: int32 up_to = 2;
     */
    upTo: number;
}
/**
 * ProjectMeta is the metadata for a project.
 *
 * @generated from protobuf message polarsignals.admin.v1alpha1.ProjectMeta
 */
export interface ProjectMeta {
    /**
     * ID is the id of the project.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * Name is the name of the project.
     *
     * @generated from protobuf field: string name = 2;
     */
    name: string;
}
/**
 * GetAllOrganizationUsageDataResponse is the response object for GetAllOrganizationUsageData.
 *
 * @generated from protobuf message polarsignals.admin.v1alpha1.GetAllOrganizationUsageDataResponse
 */
export interface GetAllOrganizationUsageDataResponse {
    /**
     * UsageData is the usage data for all organizations.
     *
     * @generated from protobuf field: repeated polarsignals.admin.v1alpha1.OrganizationUsageData usage_data = 1;
     */
    usageData: OrganizationUsageData[];
}
/**
 * UpdateOrganizationPOBillingRequest is the request object for UpdateOrganizationPOBilling.
 *
 * @generated from protobuf message polarsignals.admin.v1alpha1.UpdateOrganizationPOBillingRequest
 */
export interface UpdateOrganizationPOBillingRequest {
    /**
     * ID is the id of the organization to update.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * IsPoBilling is the new PO Billing flag for the organization.
     *
     * @generated from protobuf field: bool is_po_billing = 2;
     */
    isPoBilling: boolean;
}
/**
 * UpdateOrganizationPOBillingResponse is the response object for UpdateOrganizationPOBilling.
 *
 * @generated from protobuf message polarsignals.admin.v1alpha1.UpdateOrganizationPOBillingResponse
 */
export interface UpdateOrganizationPOBillingResponse {
}
// @generated message type with reflection information, may provide speed optimized methods
class UpdateOrganizationPriceIDRequest$Type extends MessageType<UpdateOrganizationPriceIDRequest> {
    constructor() {
        super("polarsignals.admin.v1alpha1.UpdateOrganizationPriceIDRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "price_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateOrganizationPriceIDRequest>): UpdateOrganizationPriceIDRequest {
        const message = { id: "", priceId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateOrganizationPriceIDRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateOrganizationPriceIDRequest): UpdateOrganizationPriceIDRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string price_id */ 2:
                    message.priceId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateOrganizationPriceIDRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string price_id = 2; */
        if (message.priceId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.priceId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.admin.v1alpha1.UpdateOrganizationPriceIDRequest
 */
export const UpdateOrganizationPriceIDRequest = new UpdateOrganizationPriceIDRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateOrganizationPriceIDResponse$Type extends MessageType<UpdateOrganizationPriceIDResponse> {
    constructor() {
        super("polarsignals.admin.v1alpha1.UpdateOrganizationPriceIDResponse", []);
    }
    create(value?: PartialMessage<UpdateOrganizationPriceIDResponse>): UpdateOrganizationPriceIDResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateOrganizationPriceIDResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateOrganizationPriceIDResponse): UpdateOrganizationPriceIDResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateOrganizationPriceIDResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.admin.v1alpha1.UpdateOrganizationPriceIDResponse
 */
export const UpdateOrganizationPriceIDResponse = new UpdateOrganizationPriceIDResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateOrganizationTrialEndRequest$Type extends MessageType<UpdateOrganizationTrialEndRequest> {
    constructor() {
        super("polarsignals.admin.v1alpha1.UpdateOrganizationTrialEndRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "trial_end", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<UpdateOrganizationTrialEndRequest>): UpdateOrganizationTrialEndRequest {
        const message = { id: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateOrganizationTrialEndRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateOrganizationTrialEndRequest): UpdateOrganizationTrialEndRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* google.protobuf.Timestamp trial_end */ 2:
                    message.trialEnd = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.trialEnd);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateOrganizationTrialEndRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* google.protobuf.Timestamp trial_end = 2; */
        if (message.trialEnd)
            Timestamp.internalBinaryWrite(message.trialEnd, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.admin.v1alpha1.UpdateOrganizationTrialEndRequest
 */
export const UpdateOrganizationTrialEndRequest = new UpdateOrganizationTrialEndRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateOrganizationTrialEndResponse$Type extends MessageType<UpdateOrganizationTrialEndResponse> {
    constructor() {
        super("polarsignals.admin.v1alpha1.UpdateOrganizationTrialEndResponse", []);
    }
    create(value?: PartialMessage<UpdateOrganizationTrialEndResponse>): UpdateOrganizationTrialEndResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateOrganizationTrialEndResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateOrganizationTrialEndResponse): UpdateOrganizationTrialEndResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateOrganizationTrialEndResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.admin.v1alpha1.UpdateOrganizationTrialEndResponse
 */
export const UpdateOrganizationTrialEndResponse = new UpdateOrganizationTrialEndResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAllOrganizationUsageDataRequest$Type extends MessageType<GetAllOrganizationUsageDataRequest> {
    constructor() {
        super("polarsignals.admin.v1alpha1.GetAllOrganizationUsageDataRequest", []);
    }
    create(value?: PartialMessage<GetAllOrganizationUsageDataRequest>): GetAllOrganizationUsageDataRequest {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAllOrganizationUsageDataRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAllOrganizationUsageDataRequest): GetAllOrganizationUsageDataRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetAllOrganizationUsageDataRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.admin.v1alpha1.GetAllOrganizationUsageDataRequest
 */
export const GetAllOrganizationUsageDataRequest = new GetAllOrganizationUsageDataRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class OrganizationUsageData$Type extends MessageType<OrganizationUsageData> {
    constructor() {
        super("polarsignals.admin.v1alpha1.OrganizationUsageData", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "projects", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ProjectMeta },
            { no: 4, name: "bytes_4w", kind: "scalar", jsonName: "bytes4w", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 5, name: "bytes_7d", kind: "scalar", jsonName: "bytes7d", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 6, name: "bytes_24h", kind: "scalar", jsonName: "bytes24h", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 7, name: "bytes_24h_rate", kind: "scalar", jsonName: "bytes24hRate", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 8, name: "queries_4w", kind: "scalar", jsonName: "queries4w", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 9, name: "queries_24h", kind: "scalar", jsonName: "queries24h", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 10, name: "billing_estimate", kind: "message", T: () => BillingEstimate },
            { no: 11, name: "bytes_last_calendar_month", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<OrganizationUsageData>): OrganizationUsageData {
        const message = { id: "", name: "", projects: [], bytes4W: 0n, bytes7D: 0n, bytes24H: 0n, bytes24HRate: 0n, queries4W: 0n, queries24H: 0n, bytesLastCalendarMonth: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<OrganizationUsageData>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: OrganizationUsageData): OrganizationUsageData {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* repeated polarsignals.admin.v1alpha1.ProjectMeta projects */ 3:
                    message.projects.push(ProjectMeta.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 bytes_4w = 4 [json_name = "bytes4w"];*/ 4:
                    message.bytes4W = reader.int64().toBigInt();
                    break;
                case /* int64 bytes_7d = 5 [json_name = "bytes7d"];*/ 5:
                    message.bytes7D = reader.int64().toBigInt();
                    break;
                case /* int64 bytes_24h = 6 [json_name = "bytes24h"];*/ 6:
                    message.bytes24H = reader.int64().toBigInt();
                    break;
                case /* int64 bytes_24h_rate = 7 [json_name = "bytes24hRate"];*/ 7:
                    message.bytes24HRate = reader.int64().toBigInt();
                    break;
                case /* int64 queries_4w = 8 [json_name = "queries4w"];*/ 8:
                    message.queries4W = reader.int64().toBigInt();
                    break;
                case /* int64 queries_24h = 9 [json_name = "queries24h"];*/ 9:
                    message.queries24H = reader.int64().toBigInt();
                    break;
                case /* polarsignals.admin.v1alpha1.BillingEstimate billing_estimate */ 10:
                    message.billingEstimate = BillingEstimate.internalBinaryRead(reader, reader.uint32(), options, message.billingEstimate);
                    break;
                case /* int64 bytes_last_calendar_month */ 11:
                    message.bytesLastCalendarMonth = reader.int64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: OrganizationUsageData, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* repeated polarsignals.admin.v1alpha1.ProjectMeta projects = 3; */
        for (let i = 0; i < message.projects.length; i++)
            ProjectMeta.internalBinaryWrite(message.projects[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* int64 bytes_4w = 4 [json_name = "bytes4w"]; */
        if (message.bytes4W !== 0n)
            writer.tag(4, WireType.Varint).int64(message.bytes4W);
        /* int64 bytes_7d = 5 [json_name = "bytes7d"]; */
        if (message.bytes7D !== 0n)
            writer.tag(5, WireType.Varint).int64(message.bytes7D);
        /* int64 bytes_24h = 6 [json_name = "bytes24h"]; */
        if (message.bytes24H !== 0n)
            writer.tag(6, WireType.Varint).int64(message.bytes24H);
        /* int64 bytes_24h_rate = 7 [json_name = "bytes24hRate"]; */
        if (message.bytes24HRate !== 0n)
            writer.tag(7, WireType.Varint).int64(message.bytes24HRate);
        /* int64 queries_4w = 8 [json_name = "queries4w"]; */
        if (message.queries4W !== 0n)
            writer.tag(8, WireType.Varint).int64(message.queries4W);
        /* int64 queries_24h = 9 [json_name = "queries24h"]; */
        if (message.queries24H !== 0n)
            writer.tag(9, WireType.Varint).int64(message.queries24H);
        /* polarsignals.admin.v1alpha1.BillingEstimate billing_estimate = 10; */
        if (message.billingEstimate)
            BillingEstimate.internalBinaryWrite(message.billingEstimate, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* int64 bytes_last_calendar_month = 11; */
        if (message.bytesLastCalendarMonth !== 0n)
            writer.tag(11, WireType.Varint).int64(message.bytesLastCalendarMonth);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.admin.v1alpha1.OrganizationUsageData
 */
export const OrganizationUsageData = new OrganizationUsageData$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BillingEstimate$Type extends MessageType<BillingEstimate> {
    constructor() {
        super("polarsignals.admin.v1alpha1.BillingEstimate", [
            { no: 1, name: "period_start", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "period_end", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 3, name: "unit_amount", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 4, name: "units", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 5, name: "current_amount_due", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 6, name: "is_subscription_active", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "price_tiers", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => PriceTier },
            { no: 8, name: "discount", kind: "message", T: () => Discount }
        ]);
    }
    create(value?: PartialMessage<BillingEstimate>): BillingEstimate {
        const message = { periodStart: 0, periodEnd: 0, unitAmount: 0, units: 0, currentAmountDue: 0, isSubscriptionActive: false, priceTiers: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<BillingEstimate>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BillingEstimate): BillingEstimate {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 period_start */ 1:
                    message.periodStart = reader.int32();
                    break;
                case /* int32 period_end */ 2:
                    message.periodEnd = reader.int32();
                    break;
                case /* int32 unit_amount */ 3:
                    message.unitAmount = reader.int32();
                    break;
                case /* int32 units */ 4:
                    message.units = reader.int32();
                    break;
                case /* int32 current_amount_due */ 5:
                    message.currentAmountDue = reader.int32();
                    break;
                case /* bool is_subscription_active */ 6:
                    message.isSubscriptionActive = reader.bool();
                    break;
                case /* repeated polarsignals.admin.v1alpha1.PriceTier price_tiers */ 7:
                    message.priceTiers.push(PriceTier.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* polarsignals.billing.v1alpha1.Discount discount */ 8:
                    message.discount = Discount.internalBinaryRead(reader, reader.uint32(), options, message.discount);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BillingEstimate, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 period_start = 1; */
        if (message.periodStart !== 0)
            writer.tag(1, WireType.Varint).int32(message.periodStart);
        /* int32 period_end = 2; */
        if (message.periodEnd !== 0)
            writer.tag(2, WireType.Varint).int32(message.periodEnd);
        /* int32 unit_amount = 3; */
        if (message.unitAmount !== 0)
            writer.tag(3, WireType.Varint).int32(message.unitAmount);
        /* int32 units = 4; */
        if (message.units !== 0)
            writer.tag(4, WireType.Varint).int32(message.units);
        /* int32 current_amount_due = 5; */
        if (message.currentAmountDue !== 0)
            writer.tag(5, WireType.Varint).int32(message.currentAmountDue);
        /* bool is_subscription_active = 6; */
        if (message.isSubscriptionActive !== false)
            writer.tag(6, WireType.Varint).bool(message.isSubscriptionActive);
        /* repeated polarsignals.admin.v1alpha1.PriceTier price_tiers = 7; */
        for (let i = 0; i < message.priceTiers.length; i++)
            PriceTier.internalBinaryWrite(message.priceTiers[i], writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* polarsignals.billing.v1alpha1.Discount discount = 8; */
        if (message.discount)
            Discount.internalBinaryWrite(message.discount, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.admin.v1alpha1.BillingEstimate
 */
export const BillingEstimate = new BillingEstimate$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PriceTier$Type extends MessageType<PriceTier> {
    constructor() {
        super("polarsignals.admin.v1alpha1.PriceTier", [
            { no: 1, name: "unit_amount", kind: "scalar", T: 5 /*ScalarType.INT32*/ },
            { no: 2, name: "up_to", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<PriceTier>): PriceTier {
        const message = { unitAmount: 0, upTo: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<PriceTier>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PriceTier): PriceTier {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 unit_amount */ 1:
                    message.unitAmount = reader.int32();
                    break;
                case /* int32 up_to */ 2:
                    message.upTo = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PriceTier, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 unit_amount = 1; */
        if (message.unitAmount !== 0)
            writer.tag(1, WireType.Varint).int32(message.unitAmount);
        /* int32 up_to = 2; */
        if (message.upTo !== 0)
            writer.tag(2, WireType.Varint).int32(message.upTo);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.admin.v1alpha1.PriceTier
 */
export const PriceTier = new PriceTier$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ProjectMeta$Type extends MessageType<ProjectMeta> {
    constructor() {
        super("polarsignals.admin.v1alpha1.ProjectMeta", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ProjectMeta>): ProjectMeta {
        const message = { id: "", name: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ProjectMeta>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ProjectMeta): ProjectMeta {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ProjectMeta, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.admin.v1alpha1.ProjectMeta
 */
export const ProjectMeta = new ProjectMeta$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAllOrganizationUsageDataResponse$Type extends MessageType<GetAllOrganizationUsageDataResponse> {
    constructor() {
        super("polarsignals.admin.v1alpha1.GetAllOrganizationUsageDataResponse", [
            { no: 1, name: "usage_data", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => OrganizationUsageData }
        ]);
    }
    create(value?: PartialMessage<GetAllOrganizationUsageDataResponse>): GetAllOrganizationUsageDataResponse {
        const message = { usageData: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetAllOrganizationUsageDataResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAllOrganizationUsageDataResponse): GetAllOrganizationUsageDataResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated polarsignals.admin.v1alpha1.OrganizationUsageData usage_data */ 1:
                    message.usageData.push(OrganizationUsageData.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAllOrganizationUsageDataResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated polarsignals.admin.v1alpha1.OrganizationUsageData usage_data = 1; */
        for (let i = 0; i < message.usageData.length; i++)
            OrganizationUsageData.internalBinaryWrite(message.usageData[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.admin.v1alpha1.GetAllOrganizationUsageDataResponse
 */
export const GetAllOrganizationUsageDataResponse = new GetAllOrganizationUsageDataResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateOrganizationPOBillingRequest$Type extends MessageType<UpdateOrganizationPOBillingRequest> {
    constructor() {
        super("polarsignals.admin.v1alpha1.UpdateOrganizationPOBillingRequest", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "is_po_billing", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateOrganizationPOBillingRequest>): UpdateOrganizationPOBillingRequest {
        const message = { id: "", isPoBilling: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateOrganizationPOBillingRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateOrganizationPOBillingRequest): UpdateOrganizationPOBillingRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* bool is_po_billing */ 2:
                    message.isPoBilling = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateOrganizationPOBillingRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* bool is_po_billing = 2; */
        if (message.isPoBilling !== false)
            writer.tag(2, WireType.Varint).bool(message.isPoBilling);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.admin.v1alpha1.UpdateOrganizationPOBillingRequest
 */
export const UpdateOrganizationPOBillingRequest = new UpdateOrganizationPOBillingRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateOrganizationPOBillingResponse$Type extends MessageType<UpdateOrganizationPOBillingResponse> {
    constructor() {
        super("polarsignals.admin.v1alpha1.UpdateOrganizationPOBillingResponse", []);
    }
    create(value?: PartialMessage<UpdateOrganizationPOBillingResponse>): UpdateOrganizationPOBillingResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UpdateOrganizationPOBillingResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateOrganizationPOBillingResponse): UpdateOrganizationPOBillingResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: UpdateOrganizationPOBillingResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.admin.v1alpha1.UpdateOrganizationPOBillingResponse
 */
export const UpdateOrganizationPOBillingResponse = new UpdateOrganizationPOBillingResponse$Type();
/**
 * @generated ServiceType for protobuf service polarsignals.admin.v1alpha1.AdminService
 */
export const AdminService = new ServiceType("polarsignals.admin.v1alpha1.AdminService", [
    { name: "UpdateOrganizationPriceID", options: { "google.api.http": { post: "/v1/organizations/{id}/priceid" } }, I: UpdateOrganizationPriceIDRequest, O: UpdateOrganizationPriceIDResponse },
    { name: "UpdateOrganizationTrialEnd", options: { "google.api.http": { post: "/v1/organizations/{id}/trialend" } }, I: UpdateOrganizationTrialEndRequest, O: UpdateOrganizationTrialEndResponse },
    { name: "GetAllOrganizationUsageData", options: { "google.api.http": { get: "/v1/organizations/usage" } }, I: GetAllOrganizationUsageDataRequest, O: GetAllOrganizationUsageDataResponse },
    { name: "UpdateOrganizationPOBilling", options: { "google.api.http": { post: "/v1/organizations/{id}/pobilling" } }, I: UpdateOrganizationPOBillingRequest, O: UpdateOrganizationPOBillingResponse }
]);
