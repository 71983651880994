// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies
// @generated from protobuf file "polarsignals/project/v1alpha1/debuginfo.proto" (package "polarsignals.project.v1alpha1", syntax proto3)
// tslint:disable
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../../google/protobuf/timestamp";
import { DebuginfoType } from "../../../parca/debuginfo/v1alpha1/debuginfo";
/**
 * GetProjectDebuginfosRequest is the request for GetProjectDebuginfos.
 *
 * @generated from protobuf message polarsignals.project.v1alpha1.GetProjectDebuginfosRequest
 */
export interface GetProjectDebuginfosRequest {
    /**
     * The ID of the project to get debug infos for.
     *
     * @generated from protobuf field: string project_id = 1;
     */
    projectId: string;
    /**
     * The build ID of the debug info.
     *
     * @generated from protobuf field: optional string build_id = 2;
     */
    buildId?: string;
    /**
     * the token of the page that retrived
     *
     * @generated from protobuf field: string page_token = 3;
     */
    pageToken: string;
    /**
     * the number of items to return per page
     *
     * @generated from protobuf field: int32 page_size = 4;
     */
    pageSize: number;
}
/**
 * GetProjectDebuginfosResponse is the response for GetProjectDebuginfos.
 *
 * @generated from protobuf message polarsignals.project.v1alpha1.GetProjectDebuginfosResponse
 */
export interface GetProjectDebuginfosResponse {
    /**
     * token of the next page for pagination if more pages exist
     *
     * @generated from protobuf field: string next_page_token = 1;
     */
    nextPageToken: string;
    /**
     * the list of debug infos
     *
     * @generated from protobuf field: repeated polarsignals.project.v1alpha1.Debuginfo debuginfos = 2;
     */
    debuginfos: Debuginfo[];
}
/**
 * Debuginfo is the details about a debug info file.
 *
 * @generated from protobuf message polarsignals.project.v1alpha1.Debuginfo
 */
export interface Debuginfo {
    /**
     * The ID of the debug info.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * The ID of the project the debug info belongs to.
     *
     * @generated from protobuf field: string project_id = 2;
     */
    projectId: string;
    /**
     * The type of the debug info.
     *
     * @generated from protobuf field: parca.debuginfo.v1alpha1.DebuginfoType type = 3;
     */
    type: DebuginfoType;
    /**
     * The build ID of the debug info.
     *
     * @generated from protobuf field: string build_id = 4;
     */
    buildId: string;
    /**
     * The hash of the debug info.
     *
     * @generated from protobuf field: string hash = 5;
     */
    hash: string;
    /**
     * The source of the debug info.
     *
     * @generated from protobuf field: polarsignals.project.v1alpha1.Source source = 6;
     */
    source: Source;
    /**
     * The debuginfod servers this piece of debuginfo is available at.
     *
     * @generated from protobuf field: repeated string debuginfod_servers = 7;
     */
    debuginfodServers: string[];
    /**
     * The state of the debug info upload.
     *
     * @generated from protobuf field: polarsignals.project.v1alpha1.UploadState upload_state = 8;
     */
    uploadState: UploadState;
    /**
     * created_at is the time the debug info was created.
     *
     * @generated from protobuf field: google.protobuf.Timestamp created_at = 9;
     */
    createdAt?: Timestamp;
    /**
     * upload_started_at is the time the debug info upload was started.
     *
     * @generated from protobuf field: google.protobuf.Timestamp upload_started_at = 10;
     */
    uploadStartedAt?: Timestamp;
    /**
     * upload_finished_at is the time the debug info upload was finished.
     *
     * @generated from protobuf field: google.protobuf.Timestamp upload_finished_at = 11;
     */
    uploadFinishedAt?: Timestamp;
    /**
     * has_error indicates if the debug info processing has errored.
     *
     * @generated from protobuf field: polarsignals.project.v1alpha1.ProcessingState processing_state = 12;
     */
    processingState: ProcessingState;
    /**
     * quality_not_valid_elf indicates if the debug info is not a valid ELF file.
     *
     * @generated from protobuf field: bool quality_not_valid_elf = 13;
     */
    qualityNotValidElf: boolean;
    /**
     * last_check_in is the time the debug info was last checked in.
     *
     * @generated from protobuf field: google.protobuf.Timestamp last_check_in = 14;
     */
    lastCheckIn?: Timestamp;
}
/**
 * GetProjectDebuginfosCountRequest is the request for GetProjectDebuginfosCount.
 *
 * @generated from protobuf message polarsignals.project.v1alpha1.GetProjectDebuginfosCountRequest
 */
export interface GetProjectDebuginfosCountRequest {
    /**
     * The ID of the project to get debug infos count for.
     *
     * @generated from protobuf field: string project_id = 1;
     */
    projectId: string;
    /**
     * The build ID of the debug info.
     *
     * @generated from protobuf field: optional string build_id = 2;
     */
    buildId?: string;
}
/**
 * GetProjectDebuginfosCountResponse is the response for GetProjectDebuginfosCount.
 *
 * @generated from protobuf message polarsignals.project.v1alpha1.GetProjectDebuginfosCountResponse
 */
export interface GetProjectDebuginfosCountResponse {
    /**
     * The count of debug infos.
     *
     * @generated from protobuf field: int32 count = 1;
     */
    count: number;
}
/**
 * Source is the source of the debuginfo.
 *
 * @generated from protobuf enum polarsignals.project.v1alpha1.Source
 */
export enum Source {
    /**
     * To understand when no source is set we have the unknown source.
     *
     * @generated from protobuf enum value: SOURCE_UNKNOWN_UNSPECIFIED = 0;
     */
    UNKNOWN_UNSPECIFIED = 0,
    /**
     * The debuginfo was uploaded by a user/agent.
     *
     * @generated from protobuf enum value: SOURCE_UPLOAD = 1;
     */
    UPLOAD = 1,
    /**
     * The debuginfo is available from the configured debuginfod server(s).
     *
     * @generated from protobuf enum value: SOURCE_DEBUGINFOD = 2;
     */
    DEBUGINFOD = 2
}
/**
 * UploadState is the state of the debug info upload.
 *
 * @generated from protobuf enum polarsignals.project.v1alpha1.UploadState
 */
export enum UploadState {
    /**
     * The state is unknown.
     *
     * @generated from protobuf enum value: STATE_UNKNOWN_UNSPECIFIED = 0;
     */
    STATE_UNKNOWN_UNSPECIFIED = 0,
    /**
     * The debug info is being uploaded.
     *
     * @generated from protobuf enum value: STATE_UPLOADING = 1;
     */
    STATE_UPLOADING = 1,
    /**
     * The debug info has been uploaded.
     *
     * @generated from protobuf enum value: STATE_UPLOADED = 2;
     */
    STATE_UPLOADED = 2,
    /**
     * The debug info upload has failed.
     *
     * @generated from protobuf enum value: STATE_FAILED = 3;
     */
    STATE_FAILED = 3
}
/**
 * ProcessingState is the state of the debug info processing.
 *
 * @generated from protobuf enum polarsignals.project.v1alpha1.ProcessingState
 */
export enum ProcessingState {
    /**
     * The state is unknown.
     *
     * @generated from protobuf enum value: PROCESSING_STATE_UNKNOWN_UNSPECIFIED = 0;
     */
    UNKNOWN_UNSPECIFIED = 0,
    /**
     * The debug info processing has errored.
     *
     * @generated from protobuf enum value: PROCESSING_STATE_ERROR = 1;
     */
    ERROR = 1,
    /**
     * The debug info has been processed.
     *
     * @generated from protobuf enum value: PROCESSING_STATE_SUCCESS = 2;
     */
    SUCCESS = 2,
    /**
     * The debug info optimization pending.
     *
     * @generated from protobuf enum value: PROCESSING_STATE_OPTIMIZATION_PENDING = 3;
     */
    OPTIMIZATION_PENDING = 3
}
// @generated message type with reflection information, may provide speed optimized methods
class GetProjectDebuginfosRequest$Type extends MessageType<GetProjectDebuginfosRequest> {
    constructor() {
        super("polarsignals.project.v1alpha1.GetProjectDebuginfosRequest", [
            { no: 1, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "build_id", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "page_size", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetProjectDebuginfosRequest>): GetProjectDebuginfosRequest {
        const message = { projectId: "", pageToken: "", pageSize: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetProjectDebuginfosRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProjectDebuginfosRequest): GetProjectDebuginfosRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string project_id */ 1:
                    message.projectId = reader.string();
                    break;
                case /* optional string build_id */ 2:
                    message.buildId = reader.string();
                    break;
                case /* string page_token */ 3:
                    message.pageToken = reader.string();
                    break;
                case /* int32 page_size */ 4:
                    message.pageSize = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetProjectDebuginfosRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string project_id = 1; */
        if (message.projectId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.projectId);
        /* optional string build_id = 2; */
        if (message.buildId !== undefined)
            writer.tag(2, WireType.LengthDelimited).string(message.buildId);
        /* string page_token = 3; */
        if (message.pageToken !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.pageToken);
        /* int32 page_size = 4; */
        if (message.pageSize !== 0)
            writer.tag(4, WireType.Varint).int32(message.pageSize);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.project.v1alpha1.GetProjectDebuginfosRequest
 */
export const GetProjectDebuginfosRequest = new GetProjectDebuginfosRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetProjectDebuginfosResponse$Type extends MessageType<GetProjectDebuginfosResponse> {
    constructor() {
        super("polarsignals.project.v1alpha1.GetProjectDebuginfosResponse", [
            { no: 1, name: "next_page_token", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "debuginfos", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Debuginfo }
        ]);
    }
    create(value?: PartialMessage<GetProjectDebuginfosResponse>): GetProjectDebuginfosResponse {
        const message = { nextPageToken: "", debuginfos: [] };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetProjectDebuginfosResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProjectDebuginfosResponse): GetProjectDebuginfosResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string next_page_token */ 1:
                    message.nextPageToken = reader.string();
                    break;
                case /* repeated polarsignals.project.v1alpha1.Debuginfo debuginfos */ 2:
                    message.debuginfos.push(Debuginfo.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetProjectDebuginfosResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string next_page_token = 1; */
        if (message.nextPageToken !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.nextPageToken);
        /* repeated polarsignals.project.v1alpha1.Debuginfo debuginfos = 2; */
        for (let i = 0; i < message.debuginfos.length; i++)
            Debuginfo.internalBinaryWrite(message.debuginfos[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.project.v1alpha1.GetProjectDebuginfosResponse
 */
export const GetProjectDebuginfosResponse = new GetProjectDebuginfosResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Debuginfo$Type extends MessageType<Debuginfo> {
    constructor() {
        super("polarsignals.project.v1alpha1.Debuginfo", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "type", kind: "enum", T: () => ["parca.debuginfo.v1alpha1.DebuginfoType", DebuginfoType, "DEBUGINFO_TYPE_"] },
            { no: 4, name: "build_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "hash", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "source", kind: "enum", T: () => ["polarsignals.project.v1alpha1.Source", Source, "SOURCE_"] },
            { no: 7, name: "debuginfod_servers", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "upload_state", kind: "enum", T: () => ["polarsignals.project.v1alpha1.UploadState", UploadState] },
            { no: 9, name: "created_at", kind: "message", T: () => Timestamp },
            { no: 10, name: "upload_started_at", kind: "message", T: () => Timestamp },
            { no: 11, name: "upload_finished_at", kind: "message", T: () => Timestamp },
            { no: 12, name: "processing_state", kind: "enum", T: () => ["polarsignals.project.v1alpha1.ProcessingState", ProcessingState, "PROCESSING_STATE_"] },
            { no: 13, name: "quality_not_valid_elf", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 14, name: "last_check_in", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<Debuginfo>): Debuginfo {
        const message = { id: "", projectId: "", type: 0, buildId: "", hash: "", source: 0, debuginfodServers: [], uploadState: 0, processingState: 0, qualityNotValidElf: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Debuginfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Debuginfo): Debuginfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string project_id */ 2:
                    message.projectId = reader.string();
                    break;
                case /* parca.debuginfo.v1alpha1.DebuginfoType type */ 3:
                    message.type = reader.int32();
                    break;
                case /* string build_id */ 4:
                    message.buildId = reader.string();
                    break;
                case /* string hash */ 5:
                    message.hash = reader.string();
                    break;
                case /* polarsignals.project.v1alpha1.Source source */ 6:
                    message.source = reader.int32();
                    break;
                case /* repeated string debuginfod_servers */ 7:
                    message.debuginfodServers.push(reader.string());
                    break;
                case /* polarsignals.project.v1alpha1.UploadState upload_state */ 8:
                    message.uploadState = reader.int32();
                    break;
                case /* google.protobuf.Timestamp created_at */ 9:
                    message.createdAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.createdAt);
                    break;
                case /* google.protobuf.Timestamp upload_started_at */ 10:
                    message.uploadStartedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.uploadStartedAt);
                    break;
                case /* google.protobuf.Timestamp upload_finished_at */ 11:
                    message.uploadFinishedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.uploadFinishedAt);
                    break;
                case /* polarsignals.project.v1alpha1.ProcessingState processing_state */ 12:
                    message.processingState = reader.int32();
                    break;
                case /* bool quality_not_valid_elf */ 13:
                    message.qualityNotValidElf = reader.bool();
                    break;
                case /* google.protobuf.Timestamp last_check_in */ 14:
                    message.lastCheckIn = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.lastCheckIn);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Debuginfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string project_id = 2; */
        if (message.projectId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.projectId);
        /* parca.debuginfo.v1alpha1.DebuginfoType type = 3; */
        if (message.type !== 0)
            writer.tag(3, WireType.Varint).int32(message.type);
        /* string build_id = 4; */
        if (message.buildId !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.buildId);
        /* string hash = 5; */
        if (message.hash !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.hash);
        /* polarsignals.project.v1alpha1.Source source = 6; */
        if (message.source !== 0)
            writer.tag(6, WireType.Varint).int32(message.source);
        /* repeated string debuginfod_servers = 7; */
        for (let i = 0; i < message.debuginfodServers.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.debuginfodServers[i]);
        /* polarsignals.project.v1alpha1.UploadState upload_state = 8; */
        if (message.uploadState !== 0)
            writer.tag(8, WireType.Varint).int32(message.uploadState);
        /* google.protobuf.Timestamp created_at = 9; */
        if (message.createdAt)
            Timestamp.internalBinaryWrite(message.createdAt, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp upload_started_at = 10; */
        if (message.uploadStartedAt)
            Timestamp.internalBinaryWrite(message.uploadStartedAt, writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp upload_finished_at = 11; */
        if (message.uploadFinishedAt)
            Timestamp.internalBinaryWrite(message.uploadFinishedAt, writer.tag(11, WireType.LengthDelimited).fork(), options).join();
        /* polarsignals.project.v1alpha1.ProcessingState processing_state = 12; */
        if (message.processingState !== 0)
            writer.tag(12, WireType.Varint).int32(message.processingState);
        /* bool quality_not_valid_elf = 13; */
        if (message.qualityNotValidElf !== false)
            writer.tag(13, WireType.Varint).bool(message.qualityNotValidElf);
        /* google.protobuf.Timestamp last_check_in = 14; */
        if (message.lastCheckIn)
            Timestamp.internalBinaryWrite(message.lastCheckIn, writer.tag(14, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.project.v1alpha1.Debuginfo
 */
export const Debuginfo = new Debuginfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetProjectDebuginfosCountRequest$Type extends MessageType<GetProjectDebuginfosCountRequest> {
    constructor() {
        super("polarsignals.project.v1alpha1.GetProjectDebuginfosCountRequest", [
            { no: 1, name: "project_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "build_id", kind: "scalar", opt: true, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetProjectDebuginfosCountRequest>): GetProjectDebuginfosCountRequest {
        const message = { projectId: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetProjectDebuginfosCountRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProjectDebuginfosCountRequest): GetProjectDebuginfosCountRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string project_id */ 1:
                    message.projectId = reader.string();
                    break;
                case /* optional string build_id */ 2:
                    message.buildId = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetProjectDebuginfosCountRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string project_id = 1; */
        if (message.projectId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.projectId);
        /* optional string build_id = 2; */
        if (message.buildId !== undefined)
            writer.tag(2, WireType.LengthDelimited).string(message.buildId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.project.v1alpha1.GetProjectDebuginfosCountRequest
 */
export const GetProjectDebuginfosCountRequest = new GetProjectDebuginfosCountRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetProjectDebuginfosCountResponse$Type extends MessageType<GetProjectDebuginfosCountResponse> {
    constructor() {
        super("polarsignals.project.v1alpha1.GetProjectDebuginfosCountResponse", [
            { no: 1, name: "count", kind: "scalar", T: 5 /*ScalarType.INT32*/ }
        ]);
    }
    create(value?: PartialMessage<GetProjectDebuginfosCountResponse>): GetProjectDebuginfosCountResponse {
        const message = { count: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<GetProjectDebuginfosCountResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetProjectDebuginfosCountResponse): GetProjectDebuginfosCountResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int32 count */ 1:
                    message.count = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetProjectDebuginfosCountResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int32 count = 1; */
        if (message.count !== 0)
            writer.tag(1, WireType.Varint).int32(message.count);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message polarsignals.project.v1alpha1.GetProjectDebuginfosCountResponse
 */
export const GetProjectDebuginfosCountResponse = new GetProjectDebuginfosCountResponse$Type();
