import {Dispatch, MutableRefObject, SetStateAction} from 'react';

import {Icon} from '@iconify/react';
import {
  Project,
  Organization,
} from '@polarsignals/client-grpc-web/polarsignals/project/v1alpha1/project';
import {View} from '@polarsignals/client-grpc-web/polarsignals/view/v1alpha1/view';
import cx from 'classnames';

import useViews from 'hooks/data/useViews';

interface Props {
  isProjectSelectorClicked: boolean;
  organization: Organization;
  selectProject: (
    project: Project,
    organization: Organization,
    initiatedByEffect?: boolean
  ) => void;
  setProjectViews: Dispatch<SetStateAction<View[] | undefined>>;
  activeProject: Project | null;
  setIsAddProjectModalOpen: (value: SetStateAction<boolean>) => void;
  close: (
    focusableElement?: HTMLElement | MutableRefObject<HTMLElement | null> | undefined
  ) => void;
  setHoveredProject: (project: Project) => void;
}

const ProjectSelector = ({
  activeProject,
  isProjectSelectorClicked,
  organization,
  selectProject,
  setProjectViews,
  setIsAddProjectModalOpen,
  close,
  setHoveredProject,
}: Props) => {
  const {mutations: viewMutations} = useViews();

  const switchViewsShown = async (project: Project | undefined) => {
    if (project === undefined) {
      setProjectViews(undefined);
      return;
    }

    setHoveredProject(project);

    const projectViews = await viewMutations.getViewsForProject(project.id);
    setProjectViews(projectViews);
  };

  return (
    <>
      <div
        className={cx(
          isProjectSelectorClicked ? 'bg-white dark:bg-gray-700' : 'bg-gray-50 dark:bg-gray-800',
          'max-w-64 flex w-64 flex-col border-l-[1px] border-gray-200 dark:border-gray-700'
        )}
      >
        <div className="flex flex-col gap-2 py-4 px-2">
          <p className="px-2 text-xs text-gray-500">Projects</p>
          <div className="flex flex-col gap-1 text-gray-800 dark:text-gray-100">
            {organization?.projects.map((project, index) => (
              <div
                key={index}
                onClick={() => {
                  selectProject(project, organization);
                  viewMutations.setActiveView(undefined);
                  close();
                }}
                onMouseEnter={() => {
                  switchViewsShown(project);
                }}
                className={cx(
                  project.id === activeProject?.id
                    ? 'bg-indigo-600 text-white'
                    : 'hover:bg-indigo-500 hover:text-white',
                  'flex cursor-pointer items-center justify-between rounded-sm py-2 px-2.5 text-sm '
                )}
              >
                <span>{project.name}</span>
                {project.id === activeProject?.id && (
                  <Icon icon="carbon:checkmark" className="" width={12} height={12} />
                )}
              </div>
            ))}

            <div
              className="flex cursor-pointer items-center gap-2 rounded-sm px-2.5 py-2 text-sm hover:bg-indigo-500 hover:text-white"
              onClick={() => {
                setIsAddProjectModalOpen(true);
              }}
              onMouseEnter={() => {
                switchViewsShown(undefined);
              }}
            >
              <Icon icon="ei:plus" width={18} height={18} />
              <span>New Project</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectSelector;
