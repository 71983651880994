// @generated by protobuf-ts 2.9.0 with parameter generate_dependencies
// @generated from protobuf file "parca/debuginfo/v1alpha1/debuginfo.proto" (package "parca.debuginfo.v1alpha1", syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MESSAGE_TYPE } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Timestamp } from "../../../google/protobuf/timestamp";
/**
 * ShouldInitiateUploadRequest is the request for ShouldInitiateUpload.
 *
 * @generated from protobuf message parca.debuginfo.v1alpha1.ShouldInitiateUploadRequest
 */
export interface ShouldInitiateUploadRequest {
    /**
     * The build_id of the debuginfo.
     *
     * @generated from protobuf field: string build_id = 1;
     */
    buildId: string;
    /**
     * Hash of the debuginfo to upload.
     *
     * @generated from protobuf field: string hash = 2;
     */
    hash: string;
    /**
     * Force uploading even if valid debuginfos are already available.
     *
     * @generated from protobuf field: bool force = 3;
     */
    force: boolean;
    /**
     * Type of debuginfo to propose uploading.
     *
     * @generated from protobuf field: parca.debuginfo.v1alpha1.DebuginfoType type = 4;
     */
    type: DebuginfoType;
}
/**
 * ShouldInitiateUploadResponse is the response for ShouldInitiateUpload.
 *
 * @generated from protobuf message parca.debuginfo.v1alpha1.ShouldInitiateUploadResponse
 */
export interface ShouldInitiateUploadResponse {
    /**
     * Whether an upload should be initiated or not.
     *
     * @generated from protobuf field: bool should_initiate_upload = 1;
     */
    shouldInitiateUpload: boolean;
    /**
     * Reason for why an upload should be initiated or not.
     *
     * @generated from protobuf field: string reason = 2;
     */
    reason: string;
}
/**
 * InitiateUploadRequest is the request to initiate an upload.
 *
 * @generated from protobuf message parca.debuginfo.v1alpha1.InitiateUploadRequest
 */
export interface InitiateUploadRequest {
    /**
     * The build_id of the debug info to upload.
     *
     * @generated from protobuf field: string build_id = 1;
     */
    buildId: string;
    /**
     * The size of the debug info to upload.
     *
     * @generated from protobuf field: int64 size = 2;
     */
    size: bigint;
    /**
     * Hash of the debuginfo to upload.
     *
     * @generated from protobuf field: string hash = 3;
     */
    hash: string;
    /**
     * Force uploading even if valid debuginfos are already available.
     *
     * @generated from protobuf field: bool force = 4;
     */
    force: boolean;
    /**
     * Type of debuginfo to propose uploading.
     *
     * @generated from protobuf field: parca.debuginfo.v1alpha1.DebuginfoType type = 5;
     */
    type: DebuginfoType;
}
/**
 * InitiateUploadResponse is the response to an InitiateUploadRequest.
 *
 * @generated from protobuf message parca.debuginfo.v1alpha1.InitiateUploadResponse
 */
export interface InitiateUploadResponse {
    /**
     * UploadInstructions contains the instructions for the client to upload the debuginfo.
     *
     * @generated from protobuf field: parca.debuginfo.v1alpha1.UploadInstructions upload_instructions = 1;
     */
    uploadInstructions?: UploadInstructions;
}
/**
 * UploadInstructions contains the instructions for the client to upload debuginfo.
 *
 * @generated from protobuf message parca.debuginfo.v1alpha1.UploadInstructions
 */
export interface UploadInstructions {
    /**
     * The build ID of the debuginfo to upload.
     *
     * @generated from protobuf field: string build_id = 1;
     */
    buildId: string;
    /**
     * The upload_id to use for uploading.
     *
     * @generated from protobuf field: string upload_id = 2;
     */
    uploadId: string;
    /**
     * The strategy to use for uploading.
     *
     * @generated from protobuf field: parca.debuginfo.v1alpha1.UploadInstructions.UploadStrategy upload_strategy = 3;
     */
    uploadStrategy: UploadInstructions_UploadStrategy;
    /**
     * The signed url to use for uploading using a PUT request when the upload
     * strategy is SIGNED_STRATEGY_URL.
     *
     * @generated from protobuf field: string signed_url = 4;
     */
    signedUrl: string;
    /**
     * Type of debuginfo the upload instructions are for.
     *
     * @generated from protobuf field: parca.debuginfo.v1alpha1.DebuginfoType type = 5;
     */
    type: DebuginfoType;
}
/**
 * The strategy to use for uploading.
 *
 * @generated from protobuf enum parca.debuginfo.v1alpha1.UploadInstructions.UploadStrategy
 */
export enum UploadInstructions_UploadStrategy {
    /**
     * The upload is not allowed.
     *
     * @generated from protobuf enum value: UPLOAD_STRATEGY_UNSPECIFIED = 0;
     */
    UNSPECIFIED = 0,
    /**
     * The upload is allowed and should be done via the Upload RPC.
     *
     * @generated from protobuf enum value: UPLOAD_STRATEGY_GRPC = 1;
     */
    GRPC = 1,
    /**
     * The upload is allowed and should be done via a returned signed URL.
     *
     * @generated from protobuf enum value: UPLOAD_STRATEGY_SIGNED_URL = 2;
     */
    SIGNED_URL = 2
}
/**
 * MarkUploadFinishedRequest is the request to mark an upload as finished.
 *
 * @generated from protobuf message parca.debuginfo.v1alpha1.MarkUploadFinishedRequest
 */
export interface MarkUploadFinishedRequest {
    /**
     * The build_id of the debug info to mark as finished.
     *
     * @generated from protobuf field: string build_id = 1;
     */
    buildId: string;
    /**
     * The upload_id of the debug info to mark as finished.
     *
     * @generated from protobuf field: string upload_id = 2;
     */
    uploadId: string;
    /**
     * The type of debuginfo upload to mark as finished.
     *
     * @generated from protobuf field: parca.debuginfo.v1alpha1.DebuginfoType type = 3;
     */
    type: DebuginfoType;
}
/**
 * MarkUploadFinishedResponse is the response to a MarkUploadFinishedRequest.
 *
 * @generated from protobuf message parca.debuginfo.v1alpha1.MarkUploadFinishedResponse
 */
export interface MarkUploadFinishedResponse {
}
/**
 * UploadRequest upload debug info
 *
 * @generated from protobuf message parca.debuginfo.v1alpha1.UploadRequest
 */
export interface UploadRequest {
    /**
     * @generated from protobuf oneof: data
     */
    data: {
        oneofKind: "info";
        /**
         * info is the metadata for the debug info
         *
         * @generated from protobuf field: parca.debuginfo.v1alpha1.UploadInfo info = 1;
         */
        info: UploadInfo;
    } | {
        oneofKind: "chunkData";
        /**
         * chunk_data is the raw bytes of the debug info
         *
         * @generated from protobuf field: bytes chunk_data = 2;
         */
        chunkData: Uint8Array;
    } | {
        oneofKind: undefined;
    };
}
/**
 * UploadInfo contains the build_id and other metadata for the debug data
 *
 * @generated from protobuf message parca.debuginfo.v1alpha1.UploadInfo
 */
export interface UploadInfo {
    /**
     * build_id is a unique identifier for the debug data
     *
     * @generated from protobuf field: string build_id = 1;
     */
    buildId: string;
    /**
     * upload_id is a unique identifier for the upload
     *
     * @generated from protobuf field: string upload_id = 2;
     */
    uploadId: string;
    /**
     * the type of debuginfo that's being uploaded
     *
     * @generated from protobuf field: parca.debuginfo.v1alpha1.DebuginfoType type = 3;
     */
    type: DebuginfoType;
}
/**
 * UploadResponse returns the build_id and the size of the uploaded debug info
 *
 * @generated from protobuf message parca.debuginfo.v1alpha1.UploadResponse
 */
export interface UploadResponse {
    /**
     * build_id is a unique identifier for the debug data
     *
     * @generated from protobuf field: string build_id = 1;
     */
    buildId: string;
    /**
     * size is the number of bytes of the debug info
     *
     * @generated from protobuf field: uint64 size = 2;
     */
    size: bigint;
}
/**
 * Debuginfo contains metadata about a debuginfo file.
 *
 * @generated from protobuf message parca.debuginfo.v1alpha1.Debuginfo
 */
export interface Debuginfo {
    /**
     * BuildID is the build ID of the debuginfo.
     *
     * @generated from protobuf field: string build_id = 1;
     */
    buildId: string;
    /**
     * Source is the source of the debuginfo.
     *
     * @generated from protobuf field: parca.debuginfo.v1alpha1.Debuginfo.Source source = 2;
     */
    source: Debuginfo_Source;
    /**
     * DebuginfoUpload is the debuginfo upload metadata.
     *
     * @generated from protobuf field: parca.debuginfo.v1alpha1.DebuginfoUpload upload = 3;
     */
    upload?: DebuginfoUpload;
    /**
     * Quality is the quality of the debuginfo. This is set asynchonously by the
     * symbolizer when the debuginfo is actually used.
     *
     * @generated from protobuf field: parca.debuginfo.v1alpha1.DebuginfoQuality quality = 4;
     */
    quality?: DebuginfoQuality;
    /**
     * The debuginfod servers this piece of debuginfo is available at.
     *
     * @generated from protobuf field: repeated string debuginfod_servers = 5;
     */
    debuginfodServers: string[];
    /**
     * The type of debuginfo.
     *
     * @generated from protobuf field: parca.debuginfo.v1alpha1.DebuginfoType type = 6;
     */
    type: DebuginfoType;
}
/**
 * Source is the source of the debuginfo.
 *
 * @generated from protobuf enum parca.debuginfo.v1alpha1.Debuginfo.Source
 */
export enum Debuginfo_Source {
    /**
     * To understand when no source is set we have the unknown source.
     *
     * @generated from protobuf enum value: SOURCE_UNKNOWN_UNSPECIFIED = 0;
     */
    UNKNOWN_UNSPECIFIED = 0,
    /**
     * The debuginfo was uploaded by a user/agent.
     *
     * @generated from protobuf enum value: SOURCE_UPLOAD = 1;
     */
    UPLOAD = 1,
    /**
     * The debuginfo is available from the configured debuginfod server(s).
     *
     * @generated from protobuf enum value: SOURCE_DEBUGINFOD = 2;
     */
    DEBUGINFOD = 2
}
/**
 * DebuginfoUpload contains metadata about a debuginfo upload.
 *
 * @generated from protobuf message parca.debuginfo.v1alpha1.DebuginfoUpload
 */
export interface DebuginfoUpload {
    /**
     * UploadID is the ID of the debuginfo upload.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * Hash is the hash of the debuginfo.
     *
     * @generated from protobuf field: string hash = 2;
     */
    hash: string;
    /**
     * State is the current state of the debuginfo upload.
     *
     * @generated from protobuf field: parca.debuginfo.v1alpha1.DebuginfoUpload.State state = 3;
     */
    state: DebuginfoUpload_State;
    /**
     * StartedAt is the time the debuginfo upload was started.
     *
     * @generated from protobuf field: google.protobuf.Timestamp started_at = 4;
     */
    startedAt?: Timestamp;
    /**
     * FinishedAt is the time the debuginfo upload was finished.
     *
     * @generated from protobuf field: google.protobuf.Timestamp finished_at = 5;
     */
    finishedAt?: Timestamp;
}
/**
 * The state of the debuginfo upload.
 *
 * @generated from protobuf enum parca.debuginfo.v1alpha1.DebuginfoUpload.State
 */
export enum DebuginfoUpload_State {
    /**
     * To understand when no upload state is set we have the unknown state.
     *
     * @generated from protobuf enum value: STATE_UNKNOWN_UNSPECIFIED = 0;
     */
    UNKNOWN_UNSPECIFIED = 0,
    /**
     * The debuginfo is currently being uploaded.
     *
     * @generated from protobuf enum value: STATE_UPLOADING = 1;
     */
    UPLOADING = 1,
    /**
     * The debuginfo has been uploaded successfully.
     *
     * @generated from protobuf enum value: STATE_UPLOADED = 2;
     */
    UPLOADED = 2
}
/**
 * DebuginfoQuality is the quality of the debuginfo.
 *
 * @generated from protobuf message parca.debuginfo.v1alpha1.DebuginfoQuality
 */
export interface DebuginfoQuality {
    /**
     * The debuginfo file is not a valid ELF file.
     *
     * @generated from protobuf field: bool not_valid_elf = 1;
     */
    notValidElf: boolean;
    /**
     * Whether the debuginfo contains dwarf information.
     *
     * @generated from protobuf field: bool has_dwarf = 2;
     */
    hasDwarf: boolean;
    /**
     * Whether the debuginfo contains Go's pclntab.
     *
     * @generated from protobuf field: bool has_go_pclntab = 3;
     */
    hasGoPclntab: boolean;
    /**
     * Whether the debuginfo contains symtab.
     *
     * @generated from protobuf field: bool has_symtab = 4;
     */
    hasSymtab: boolean;
    /**
     * Whether the debuginfo contains dynsym.
     *
     * @generated from protobuf field: bool has_dynsym = 5;
     */
    hasDynsym: boolean;
}
/**
 * Types of debuginfo.
 *
 * @generated from protobuf enum parca.debuginfo.v1alpha1.DebuginfoType
 */
export enum DebuginfoType {
    /**
     * The default type that the API always supported. This type is expected to
     * contain debuginfos for symbolizaton purposes.
     *
     * @generated from protobuf enum value: DEBUGINFO_TYPE_DEBUGINFO_UNSPECIFIED = 0;
     */
    DEBUGINFO_UNSPECIFIED = 0,
    /**
     * The type to identify executables. This is meant to be used for
     * disassembling so it is expected to contain executable `.text` section.
     *
     * @generated from protobuf enum value: DEBUGINFO_TYPE_EXECUTABLE = 1;
     */
    EXECUTABLE = 1,
    /**
     * The type to identify a source tarball. This is expected to contain
     * multiple source files that debuginfo references. It is meant to show code
     * with profiling data inline.
     *
     * @generated from protobuf enum value: DEBUGINFO_TYPE_SOURCES = 2;
     */
    SOURCES = 2
}
// @generated message type with reflection information, may provide speed optimized methods
class ShouldInitiateUploadRequest$Type extends MessageType<ShouldInitiateUploadRequest> {
    constructor() {
        super("parca.debuginfo.v1alpha1.ShouldInitiateUploadRequest", [
            { no: 1, name: "build_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "hash", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "force", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "type", kind: "enum", T: () => ["parca.debuginfo.v1alpha1.DebuginfoType", DebuginfoType, "DEBUGINFO_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<ShouldInitiateUploadRequest>): ShouldInitiateUploadRequest {
        const message = { buildId: "", hash: "", force: false, type: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ShouldInitiateUploadRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ShouldInitiateUploadRequest): ShouldInitiateUploadRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string build_id */ 1:
                    message.buildId = reader.string();
                    break;
                case /* string hash */ 2:
                    message.hash = reader.string();
                    break;
                case /* bool force */ 3:
                    message.force = reader.bool();
                    break;
                case /* parca.debuginfo.v1alpha1.DebuginfoType type */ 4:
                    message.type = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ShouldInitiateUploadRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string build_id = 1; */
        if (message.buildId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.buildId);
        /* string hash = 2; */
        if (message.hash !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.hash);
        /* bool force = 3; */
        if (message.force !== false)
            writer.tag(3, WireType.Varint).bool(message.force);
        /* parca.debuginfo.v1alpha1.DebuginfoType type = 4; */
        if (message.type !== 0)
            writer.tag(4, WireType.Varint).int32(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.debuginfo.v1alpha1.ShouldInitiateUploadRequest
 */
export const ShouldInitiateUploadRequest = new ShouldInitiateUploadRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ShouldInitiateUploadResponse$Type extends MessageType<ShouldInitiateUploadResponse> {
    constructor() {
        super("parca.debuginfo.v1alpha1.ShouldInitiateUploadResponse", [
            { no: 1, name: "should_initiate_upload", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "reason", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ShouldInitiateUploadResponse>): ShouldInitiateUploadResponse {
        const message = { shouldInitiateUpload: false, reason: "" };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<ShouldInitiateUploadResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ShouldInitiateUploadResponse): ShouldInitiateUploadResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool should_initiate_upload */ 1:
                    message.shouldInitiateUpload = reader.bool();
                    break;
                case /* string reason */ 2:
                    message.reason = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ShouldInitiateUploadResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool should_initiate_upload = 1; */
        if (message.shouldInitiateUpload !== false)
            writer.tag(1, WireType.Varint).bool(message.shouldInitiateUpload);
        /* string reason = 2; */
        if (message.reason !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.reason);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.debuginfo.v1alpha1.ShouldInitiateUploadResponse
 */
export const ShouldInitiateUploadResponse = new ShouldInitiateUploadResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitiateUploadRequest$Type extends MessageType<InitiateUploadRequest> {
    constructor() {
        super("parca.debuginfo.v1alpha1.InitiateUploadRequest", [
            { no: 1, name: "build_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "size", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 0 /*LongType.BIGINT*/ },
            { no: 3, name: "hash", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "force", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "type", kind: "enum", T: () => ["parca.debuginfo.v1alpha1.DebuginfoType", DebuginfoType, "DEBUGINFO_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<InitiateUploadRequest>): InitiateUploadRequest {
        const message = { buildId: "", size: 0n, hash: "", force: false, type: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InitiateUploadRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitiateUploadRequest): InitiateUploadRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string build_id */ 1:
                    message.buildId = reader.string();
                    break;
                case /* int64 size */ 2:
                    message.size = reader.int64().toBigInt();
                    break;
                case /* string hash */ 3:
                    message.hash = reader.string();
                    break;
                case /* bool force */ 4:
                    message.force = reader.bool();
                    break;
                case /* parca.debuginfo.v1alpha1.DebuginfoType type */ 5:
                    message.type = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InitiateUploadRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string build_id = 1; */
        if (message.buildId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.buildId);
        /* int64 size = 2; */
        if (message.size !== 0n)
            writer.tag(2, WireType.Varint).int64(message.size);
        /* string hash = 3; */
        if (message.hash !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.hash);
        /* bool force = 4; */
        if (message.force !== false)
            writer.tag(4, WireType.Varint).bool(message.force);
        /* parca.debuginfo.v1alpha1.DebuginfoType type = 5; */
        if (message.type !== 0)
            writer.tag(5, WireType.Varint).int32(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.debuginfo.v1alpha1.InitiateUploadRequest
 */
export const InitiateUploadRequest = new InitiateUploadRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class InitiateUploadResponse$Type extends MessageType<InitiateUploadResponse> {
    constructor() {
        super("parca.debuginfo.v1alpha1.InitiateUploadResponse", [
            { no: 1, name: "upload_instructions", kind: "message", T: () => UploadInstructions }
        ]);
    }
    create(value?: PartialMessage<InitiateUploadResponse>): InitiateUploadResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<InitiateUploadResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: InitiateUploadResponse): InitiateUploadResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* parca.debuginfo.v1alpha1.UploadInstructions upload_instructions */ 1:
                    message.uploadInstructions = UploadInstructions.internalBinaryRead(reader, reader.uint32(), options, message.uploadInstructions);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: InitiateUploadResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* parca.debuginfo.v1alpha1.UploadInstructions upload_instructions = 1; */
        if (message.uploadInstructions)
            UploadInstructions.internalBinaryWrite(message.uploadInstructions, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.debuginfo.v1alpha1.InitiateUploadResponse
 */
export const InitiateUploadResponse = new InitiateUploadResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UploadInstructions$Type extends MessageType<UploadInstructions> {
    constructor() {
        super("parca.debuginfo.v1alpha1.UploadInstructions", [
            { no: 1, name: "build_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "upload_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "upload_strategy", kind: "enum", T: () => ["parca.debuginfo.v1alpha1.UploadInstructions.UploadStrategy", UploadInstructions_UploadStrategy, "UPLOAD_STRATEGY_"] },
            { no: 4, name: "signed_url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "type", kind: "enum", T: () => ["parca.debuginfo.v1alpha1.DebuginfoType", DebuginfoType, "DEBUGINFO_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<UploadInstructions>): UploadInstructions {
        const message = { buildId: "", uploadId: "", uploadStrategy: 0, signedUrl: "", type: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UploadInstructions>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UploadInstructions): UploadInstructions {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string build_id */ 1:
                    message.buildId = reader.string();
                    break;
                case /* string upload_id */ 2:
                    message.uploadId = reader.string();
                    break;
                case /* parca.debuginfo.v1alpha1.UploadInstructions.UploadStrategy upload_strategy */ 3:
                    message.uploadStrategy = reader.int32();
                    break;
                case /* string signed_url */ 4:
                    message.signedUrl = reader.string();
                    break;
                case /* parca.debuginfo.v1alpha1.DebuginfoType type */ 5:
                    message.type = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UploadInstructions, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string build_id = 1; */
        if (message.buildId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.buildId);
        /* string upload_id = 2; */
        if (message.uploadId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.uploadId);
        /* parca.debuginfo.v1alpha1.UploadInstructions.UploadStrategy upload_strategy = 3; */
        if (message.uploadStrategy !== 0)
            writer.tag(3, WireType.Varint).int32(message.uploadStrategy);
        /* string signed_url = 4; */
        if (message.signedUrl !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.signedUrl);
        /* parca.debuginfo.v1alpha1.DebuginfoType type = 5; */
        if (message.type !== 0)
            writer.tag(5, WireType.Varint).int32(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.debuginfo.v1alpha1.UploadInstructions
 */
export const UploadInstructions = new UploadInstructions$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MarkUploadFinishedRequest$Type extends MessageType<MarkUploadFinishedRequest> {
    constructor() {
        super("parca.debuginfo.v1alpha1.MarkUploadFinishedRequest", [
            { no: 1, name: "build_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "upload_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "type", kind: "enum", T: () => ["parca.debuginfo.v1alpha1.DebuginfoType", DebuginfoType, "DEBUGINFO_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<MarkUploadFinishedRequest>): MarkUploadFinishedRequest {
        const message = { buildId: "", uploadId: "", type: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MarkUploadFinishedRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MarkUploadFinishedRequest): MarkUploadFinishedRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string build_id */ 1:
                    message.buildId = reader.string();
                    break;
                case /* string upload_id */ 2:
                    message.uploadId = reader.string();
                    break;
                case /* parca.debuginfo.v1alpha1.DebuginfoType type */ 3:
                    message.type = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MarkUploadFinishedRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string build_id = 1; */
        if (message.buildId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.buildId);
        /* string upload_id = 2; */
        if (message.uploadId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.uploadId);
        /* parca.debuginfo.v1alpha1.DebuginfoType type = 3; */
        if (message.type !== 0)
            writer.tag(3, WireType.Varint).int32(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.debuginfo.v1alpha1.MarkUploadFinishedRequest
 */
export const MarkUploadFinishedRequest = new MarkUploadFinishedRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MarkUploadFinishedResponse$Type extends MessageType<MarkUploadFinishedResponse> {
    constructor() {
        super("parca.debuginfo.v1alpha1.MarkUploadFinishedResponse", []);
    }
    create(value?: PartialMessage<MarkUploadFinishedResponse>): MarkUploadFinishedResponse {
        const message = {};
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<MarkUploadFinishedResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MarkUploadFinishedResponse): MarkUploadFinishedResponse {
        return target ?? this.create();
    }
    internalBinaryWrite(message: MarkUploadFinishedResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.debuginfo.v1alpha1.MarkUploadFinishedResponse
 */
export const MarkUploadFinishedResponse = new MarkUploadFinishedResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UploadRequest$Type extends MessageType<UploadRequest> {
    constructor() {
        super("parca.debuginfo.v1alpha1.UploadRequest", [
            { no: 1, name: "info", kind: "message", oneof: "data", T: () => UploadInfo },
            { no: 2, name: "chunk_data", kind: "scalar", oneof: "data", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<UploadRequest>): UploadRequest {
        const message = { data: { oneofKind: undefined } };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UploadRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UploadRequest): UploadRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* parca.debuginfo.v1alpha1.UploadInfo info */ 1:
                    message.data = {
                        oneofKind: "info",
                        info: UploadInfo.internalBinaryRead(reader, reader.uint32(), options, (message.data as any).info)
                    };
                    break;
                case /* bytes chunk_data */ 2:
                    message.data = {
                        oneofKind: "chunkData",
                        chunkData: reader.bytes()
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UploadRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* parca.debuginfo.v1alpha1.UploadInfo info = 1; */
        if (message.data.oneofKind === "info")
            UploadInfo.internalBinaryWrite(message.data.info, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* bytes chunk_data = 2; */
        if (message.data.oneofKind === "chunkData")
            writer.tag(2, WireType.LengthDelimited).bytes(message.data.chunkData);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.debuginfo.v1alpha1.UploadRequest
 */
export const UploadRequest = new UploadRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UploadInfo$Type extends MessageType<UploadInfo> {
    constructor() {
        super("parca.debuginfo.v1alpha1.UploadInfo", [
            { no: 1, name: "build_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "upload_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "type", kind: "enum", T: () => ["parca.debuginfo.v1alpha1.DebuginfoType", DebuginfoType, "DEBUGINFO_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<UploadInfo>): UploadInfo {
        const message = { buildId: "", uploadId: "", type: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UploadInfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UploadInfo): UploadInfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string build_id */ 1:
                    message.buildId = reader.string();
                    break;
                case /* string upload_id */ 2:
                    message.uploadId = reader.string();
                    break;
                case /* parca.debuginfo.v1alpha1.DebuginfoType type */ 3:
                    message.type = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UploadInfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string build_id = 1; */
        if (message.buildId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.buildId);
        /* string upload_id = 2; */
        if (message.uploadId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.uploadId);
        /* parca.debuginfo.v1alpha1.DebuginfoType type = 3; */
        if (message.type !== 0)
            writer.tag(3, WireType.Varint).int32(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.debuginfo.v1alpha1.UploadInfo
 */
export const UploadInfo = new UploadInfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UploadResponse$Type extends MessageType<UploadResponse> {
    constructor() {
        super("parca.debuginfo.v1alpha1.UploadResponse", [
            { no: 1, name: "build_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "size", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 0 /*LongType.BIGINT*/ }
        ]);
    }
    create(value?: PartialMessage<UploadResponse>): UploadResponse {
        const message = { buildId: "", size: 0n };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<UploadResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UploadResponse): UploadResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string build_id */ 1:
                    message.buildId = reader.string();
                    break;
                case /* uint64 size */ 2:
                    message.size = reader.uint64().toBigInt();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UploadResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string build_id = 1; */
        if (message.buildId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.buildId);
        /* uint64 size = 2; */
        if (message.size !== 0n)
            writer.tag(2, WireType.Varint).uint64(message.size);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.debuginfo.v1alpha1.UploadResponse
 */
export const UploadResponse = new UploadResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Debuginfo$Type extends MessageType<Debuginfo> {
    constructor() {
        super("parca.debuginfo.v1alpha1.Debuginfo", [
            { no: 1, name: "build_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "source", kind: "enum", T: () => ["parca.debuginfo.v1alpha1.Debuginfo.Source", Debuginfo_Source, "SOURCE_"] },
            { no: 3, name: "upload", kind: "message", T: () => DebuginfoUpload },
            { no: 4, name: "quality", kind: "message", T: () => DebuginfoQuality },
            { no: 5, name: "debuginfod_servers", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "type", kind: "enum", T: () => ["parca.debuginfo.v1alpha1.DebuginfoType", DebuginfoType, "DEBUGINFO_TYPE_"] }
        ]);
    }
    create(value?: PartialMessage<Debuginfo>): Debuginfo {
        const message = { buildId: "", source: 0, debuginfodServers: [], type: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<Debuginfo>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Debuginfo): Debuginfo {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string build_id */ 1:
                    message.buildId = reader.string();
                    break;
                case /* parca.debuginfo.v1alpha1.Debuginfo.Source source */ 2:
                    message.source = reader.int32();
                    break;
                case /* parca.debuginfo.v1alpha1.DebuginfoUpload upload */ 3:
                    message.upload = DebuginfoUpload.internalBinaryRead(reader, reader.uint32(), options, message.upload);
                    break;
                case /* parca.debuginfo.v1alpha1.DebuginfoQuality quality */ 4:
                    message.quality = DebuginfoQuality.internalBinaryRead(reader, reader.uint32(), options, message.quality);
                    break;
                case /* repeated string debuginfod_servers */ 5:
                    message.debuginfodServers.push(reader.string());
                    break;
                case /* parca.debuginfo.v1alpha1.DebuginfoType type */ 6:
                    message.type = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Debuginfo, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string build_id = 1; */
        if (message.buildId !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.buildId);
        /* parca.debuginfo.v1alpha1.Debuginfo.Source source = 2; */
        if (message.source !== 0)
            writer.tag(2, WireType.Varint).int32(message.source);
        /* parca.debuginfo.v1alpha1.DebuginfoUpload upload = 3; */
        if (message.upload)
            DebuginfoUpload.internalBinaryWrite(message.upload, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* parca.debuginfo.v1alpha1.DebuginfoQuality quality = 4; */
        if (message.quality)
            DebuginfoQuality.internalBinaryWrite(message.quality, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* repeated string debuginfod_servers = 5; */
        for (let i = 0; i < message.debuginfodServers.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.debuginfodServers[i]);
        /* parca.debuginfo.v1alpha1.DebuginfoType type = 6; */
        if (message.type !== 0)
            writer.tag(6, WireType.Varint).int32(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.debuginfo.v1alpha1.Debuginfo
 */
export const Debuginfo = new Debuginfo$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DebuginfoUpload$Type extends MessageType<DebuginfoUpload> {
    constructor() {
        super("parca.debuginfo.v1alpha1.DebuginfoUpload", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "hash", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "state", kind: "enum", T: () => ["parca.debuginfo.v1alpha1.DebuginfoUpload.State", DebuginfoUpload_State, "STATE_"] },
            { no: 4, name: "started_at", kind: "message", T: () => Timestamp },
            { no: 5, name: "finished_at", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<DebuginfoUpload>): DebuginfoUpload {
        const message = { id: "", hash: "", state: 0 };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DebuginfoUpload>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DebuginfoUpload): DebuginfoUpload {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string hash */ 2:
                    message.hash = reader.string();
                    break;
                case /* parca.debuginfo.v1alpha1.DebuginfoUpload.State state */ 3:
                    message.state = reader.int32();
                    break;
                case /* google.protobuf.Timestamp started_at */ 4:
                    message.startedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.startedAt);
                    break;
                case /* google.protobuf.Timestamp finished_at */ 5:
                    message.finishedAt = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.finishedAt);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DebuginfoUpload, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string hash = 2; */
        if (message.hash !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.hash);
        /* parca.debuginfo.v1alpha1.DebuginfoUpload.State state = 3; */
        if (message.state !== 0)
            writer.tag(3, WireType.Varint).int32(message.state);
        /* google.protobuf.Timestamp started_at = 4; */
        if (message.startedAt)
            Timestamp.internalBinaryWrite(message.startedAt, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp finished_at = 5; */
        if (message.finishedAt)
            Timestamp.internalBinaryWrite(message.finishedAt, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.debuginfo.v1alpha1.DebuginfoUpload
 */
export const DebuginfoUpload = new DebuginfoUpload$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DebuginfoQuality$Type extends MessageType<DebuginfoQuality> {
    constructor() {
        super("parca.debuginfo.v1alpha1.DebuginfoQuality", [
            { no: 1, name: "not_valid_elf", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "has_dwarf", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "has_go_pclntab", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "has_symtab", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "has_dynsym", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<DebuginfoQuality>): DebuginfoQuality {
        const message = { notValidElf: false, hasDwarf: false, hasGoPclntab: false, hasSymtab: false, hasDynsym: false };
        globalThis.Object.defineProperty(message, MESSAGE_TYPE, { enumerable: false, value: this });
        if (value !== undefined)
            reflectionMergePartial<DebuginfoQuality>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DebuginfoQuality): DebuginfoQuality {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool not_valid_elf */ 1:
                    message.notValidElf = reader.bool();
                    break;
                case /* bool has_dwarf */ 2:
                    message.hasDwarf = reader.bool();
                    break;
                case /* bool has_go_pclntab */ 3:
                    message.hasGoPclntab = reader.bool();
                    break;
                case /* bool has_symtab */ 4:
                    message.hasSymtab = reader.bool();
                    break;
                case /* bool has_dynsym */ 5:
                    message.hasDynsym = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DebuginfoQuality, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool not_valid_elf = 1; */
        if (message.notValidElf !== false)
            writer.tag(1, WireType.Varint).bool(message.notValidElf);
        /* bool has_dwarf = 2; */
        if (message.hasDwarf !== false)
            writer.tag(2, WireType.Varint).bool(message.hasDwarf);
        /* bool has_go_pclntab = 3; */
        if (message.hasGoPclntab !== false)
            writer.tag(3, WireType.Varint).bool(message.hasGoPclntab);
        /* bool has_symtab = 4; */
        if (message.hasSymtab !== false)
            writer.tag(4, WireType.Varint).bool(message.hasSymtab);
        /* bool has_dynsym = 5; */
        if (message.hasDynsym !== false)
            writer.tag(5, WireType.Varint).bool(message.hasDynsym);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message parca.debuginfo.v1alpha1.DebuginfoQuality
 */
export const DebuginfoQuality = new DebuginfoQuality$Type();
/**
 * @generated ServiceType for protobuf service parca.debuginfo.v1alpha1.DebuginfoService
 */
export const DebuginfoService = new ServiceType("parca.debuginfo.v1alpha1.DebuginfoService", [
    { name: "Upload", clientStreaming: true, options: { "google.api.http": { post: "/upload", body: "*" } }, I: UploadRequest, O: UploadResponse },
    { name: "ShouldInitiateUpload", options: { "google.api.http": { post: "/shouldinitiateupload", body: "*" } }, I: ShouldInitiateUploadRequest, O: ShouldInitiateUploadResponse },
    { name: "InitiateUpload", options: { "google.api.http": { post: "/initiateupload", body: "*" } }, I: InitiateUploadRequest, O: InitiateUploadResponse },
    { name: "MarkUploadFinished", options: { "google.api.http": { post: "/markuploadfinished", body: "*" } }, I: MarkUploadFinishedRequest, O: MarkUploadFinishedResponse }
]);
